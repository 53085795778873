const GENDERS = [
  {
    key: 'm',
    value: 'MALE',
    name: 'Masculino',
  },
  {
    key: 'f',
    value: 'FEMALE',
    name: 'Feminino',
  },
];

export default GENDERS;
