<template>
  <div class="patient-list-view">
    <app-header></app-header>

    <section>
      <div class="card" width="100%">
        <div class="card-content pb-0">
          <b-col class="d-flex justify-content-start align-items-center">
            <h3>Filtros</h3>
          </b-col>

          <div class="col-12 d-flex centered justify-content-between mb-3">
            <div class="col-12 d-flex flex-wrap">
              <div class="col-12 col-lg-2 col-md-5 mt-4">
                <app-input
                  label="Pesquisar"
                  v-model="searchPatientTerm"
                  placeholder="Nome, e-mail, documento ou telefone"
                  icon="search"
                >
                </app-input>
              </div>

              <div class="col-12 col-lg-2 col-md-4 mt-4">
                <div class="label">Tipo de cliente</div>
                <b-field>
                  <b-select
                    v-model="tipo_cliente"
                    placeholder="Selecionar..."
                    class="select-custom"
                    expanded
                  >
                    <option :value="-1">Todos</option>
                    <option :value="0">Externo</option>
                    <option :value="1">Interno</option>
                  </b-select>
                </b-field>
              </div>

              <div class="col-12 col-lg-2 col-md-4 mt-4">
                <div class="label">Gênero</div>
                <b-field>
                  <b-select
                    v-model="gender"
                    placeholder="Selecionar..."
                    class="select-custom"
                    expanded
                  >
                    <option :value="-1">Todos</option>
                    <option
                      v-for="gender in genders"
                      :value="gender.key"
                      :key="gender.key"
                    >
                      {{ gender.name }}
                    </option>
                  </b-select>
                </b-field>
              </div>

              <div class="col-12 col-lg-2 col-md-4 mt-4">
                <b-field label="Resultados p/ página">
                  <b-select class="select-custom" v-model="perPage" :value="10">
                    <option :key="option" v-for="option in pageOptions">
                      {{ option }}
                    </option>
                  </b-select>
                </b-field>
              </div>

              <div
                class="row col-12 col-lg-4 p-0 m-0 d-flex justify-content-end"
              >
                <b-col class="col-12 col-lg-auto mt-2 filter-actions">
                  <b-button
                    id="add_schedule"
                    class="button-rounded button-primary px-4 mt-lg-5"
                    v-b-tooltip.hover.top="'Criar novo cliente'"
                    @click="() => $router.push({ name: 'patients.create' })"
                  >
                    <b-icon icon="plus"></b-icon> Adicionar
                  </b-button>
                </b-col>

                <b-col class="col-12 col-lg-auto mt-2 filter-custom">
                  <b-button
                    id="reset_filter"
                    class="button- px-2 mr-1"
                    v-b-tooltip.hover.top="'Limpar Filtros'"
                    @click="clearFilters()"
                    style="margin-top: 45px"
                  >
                    <b-icon icon="x-circle-fill"></b-icon>
                  </b-button>

                  <b-button
                    id="export_patients"
                    class="button- px-2 mr-1"
                    :disabled="isEmpty || isLoading"
                    v-b-tooltip.hover.top="'Exportar planilha'"
                    @click="exportPatients()"
                    style="margin-top: 45px"
                  >
                    <b-icon icon="file-earmark-spreadsheet-fill"></b-icon>
                  </b-button>
                </b-col>
              </div>
            </div>
          </div>
          <hr />
        </div>

        <div class="card-content pt-2">
          <div class="row"></div>

          <b-table
            id="table-patients"
            thead-class="thead-custom"
            :fields="fields"
            :items="items"
            :per-page="perPage"
            :total="total"
            :busy="isLoading"
            sticky-header="450px"
            no-provider-sorting
            no-provider-filtering
            small
            responsive
            show-empty
            hover
          >
            <template #cell(id)="data">
              <div class="text-center">
                {{ data.item.id }}
              </div>
            </template>

            <template #cell(name)="data">
              {{ data.value }}
            </template>

            <template #cell(email)="data">
              {{ data.item.user.email || '-' }}
            </template>

            <template #cell(cpf)="data">
              {{
                data.value
                  | mask(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
              }}
            </template>

            <template #cell(gender)="data">
              <div class="text-center">
                {{ getGenderLabel(data.value) }}
              </div>
            </template>

            <template #cell(phone)="data">
              {{ data.value | mask(/^(\d{2})(\d{5})(\d{4})$/, '($1) $2-$3') }}
            </template>

            <template #cell(schedules_count)="data">
              <div class="text-center">
                {{ data.value }}
              </div>
            </template>

            <template #cell(schedules_sum)="data">
              <div v-if="data.value > 0" class="ctab-r">
                {{ money(data.value) || '-' }}
              </div>
              <div v-else class="text-center">-</div>
            </template>

            <template #cell(active)="data">
              <div class="d-flex justify-content-center align-items-center">
                <toggle-button
                  :value="!!data.value"
                  class="m-0 table-toggle-button"
                  :disabled="true"
                  :width="60"
                  :labels="{
                    checked: 'Ativo',
                    unchecked: 'Inativo',
                  }"
                />
              </div>
            </template>

            <template #cell(options)="data">
              <b-col class="d-flex gap-2">
                <b-button
                  size="is-small"
                  class="button-primary remove-focus border-0 ml-2 h5"
                  style="border-radius: 7px"
                  v-b-tooltip.hover.left="
                    data.item.is_editable ? 'Editar' : 'Visualizar'
                  "
                  @click="
                    () =>
                      $router.push({
                        name: 'patients.edit',
                        params: { id: data.item.id },
                      })
                  "
                >
                  <b-icon
                    :icon="data.item.is_editable ? 'pencil-square' : 'eye-fill'"
                    class="text-white"
                    size="small"
                  ></b-icon>
                </b-button>

                <b-button
                  v-if="data.item.doctor_id"
                  id="delete_patient"
                  size="is-small"
                  class="bg-danger remove-focus border-0 h5 ml-2"
                  style="border-radius: 7px"
                  v-b-tooltip.hover.left="'Excluir cliente'"
                  @click="onPatientDeleteClick(data.item.id)"
                >
                  <b-icon icon="trash" class="text-white"></b-icon>
                </b-button>
              </b-col>
            </template>

            <!-- SLOT DOS CABEÇALHO -->
            <template #head()="fth">
              <div class="column-custom" :class="fth.column">
                {{ fth.label }}
              </div>
            </template>

            <!-- SLOT DE CARREGAMENTO -->
            <template #table-busy>
              <section class="section">
                <div class="text-center my-2">
                  <b-spinner class="align-middle mr-2" small></b-spinner>
                  <strong style="margin-left: 2px">Carregando...</strong>
                </div>
              </section>
            </template>

            <!-- SLOT CONTEÚDO VAZIO -->
            <template slot="empty">
              <section class="section">
                <div class="d-flex justify-content-center align-items-ter">
                  <p class="mt-4">Nenhum registro encontrado.</p>
                </div>
              </section>
            </template>
          </b-table>
        </div>

        <!-- PAGINATION -->
        <div class="card-content pt-2">
          <b-col class="col-sm-12 col-md-auto d-flex justify-content-end">
            <b-col
              class="col-sm-12 col-md-auto d-flex align-items-center mr-sm-0 mr-md-2"
            >
              <p class="p-0 m-0">
                Total de registro: <strong>{{ patients.length }}</strong>
              </p>
            </b-col>

            <b-col class="col-sm-12 col-md-auto">
              <b-pagination
                class="pagination-custom"
                page-class="pagination-custom-page"
                next-class="pagination-custom-next"
                prev-class="pagination-custom-prev"
                v-model="page"
                :per-page="perPage"
                :total-rows="total"
                :disabled="isLoading"
                aria-controls="table-patients"
                first-number
                last-number
              ></b-pagination>
            </b-col>
          </b-col>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import * as moment from 'moment';
import debounce from 'lodash/debounce';
import BuefyMixin from '@/mixins/BuefyMixin';

import PatientsService from '@/services/patients.service';
import PatientImportModal from '@/modals/Imports/PatientImportModal.vue';
import GENDERS from '@/constants/genders.constant';

export default {
  mounted() {
    this.isDoctor = this.$store.getters.doctorId ?? false;
    if (this.isDoctor) this.doctorId = this.isDoctor;
    this.loadPatients();
  },

  filters: {},

  watch: {
    isCollapse() {
      this.isInitAnimation = false;
      this.$root.$emit('bv::toggle::collapse', 'collapseFilters');
    },

    onRangeChange() {
      this.page = 1;
    },

    searchPatientTerm: debounce(function () {
      this.loadPatients();
    }, 300),

    tipo_cliente() {
      this.loadPatients();
    },

    gender() {
      this.loadPatients();
    },

    perPage() {
      this.loadPatients();
    },

    page() {
      this.loadAppointments();
    },
  },

  computed: {
    fields() {
      return [
        {
          key: 'id',
          label: 'ID',
          sortable: true,
        },
        {
          key: 'name',
          label: 'NOME',
          sortable: true,
        },
        {
          key: 'email',
          label: 'EMAIL',
          sortable: true,
        },
        {
          key: 'cpf',
          label: 'CPF',
          sortable: true,
        },
        {
          key: 'gender',
          label: 'GÊNERO',
          sortable: true,
        },
        {
          key: 'phone',
          label: 'TELEFONE',
          sortable: true,
        },
        {
          key: 'schedules_count',
          label: 'ATENDIMENTOS',
          sortable: true,
        },
        {
          key: 'schedules_sum',
          label: 'VALORES',
          sortable: true,
        },
        {
          key: 'active',
          label: 'ATIVO',
          sortable: true,
        },
        {
          key: 'options',
          label: 'OPÇÕES',
        },
      ];
    },

    items() {
      return this.patients;
    },

    isEmpty() {
      return this.patients.length === 0;
    },
  },

  data: () => ({
    mixins: [BuefyMixin],
    isLoading: false,
    isDoctor: false,
    isCollapse: false,
    isInitAnimation: false,
    isPaginated: false,
    isPaginationSimple: false,
    isPaginationRounded: false,
    searchPatientTerm: '',
    total: 0,
    page: 1,
    perPage: 10,
    doctorId: null,
    links: [],
    checked: false,
    datesFilter: [],
    patients: [],
    sortBy: '',
    isExporting: false,
    rows: 0,
    total_online: 0,
    total_patients: 0,
    total_amount: 0,
    searchTerm: '',
    onlines: '',
    footer: [],
    tipo_cliente: -1,
    gender: -1,
    genders: GENDERS,
    status: -1,
    paginationPosition: 'both',
    defaultSortDirection: 'asc',
    sortIcon: 'arrow-down',
    sortIconSize: 'is-small',
    pageOptions: [10, 20, 40],
  }),

  methods: {
    loadPatients() {
      if (this.isLoading) return;

      let filters = this.collectFilters();

      this.isLoading = true;
      PatientsService.get(filters)
        .then(({ data }) => {
          const { total, data: items, current_page } = data;

          if (this.page <= 1) {
            this.page = current_page;
            this.total = total;
          }

          this.patients = items ?? [];
        })
        .finally(() => (this.isLoading = false));
    },

    collectFilters() {
      const params = {
        page: this.page,
        perPage: this.perPage,
      };

      if (![-1, '', null].includes(this.gender))
        params['filter[gender]'] = this.gender;

      params['filter[by_doctor_schedules]'] = this.doctorId;
      params.include = 'schedules';

      if (this.searchPatientTerm) {
        params['filter[name_email_document_or_phone]'] = this.searchPatientTerm
          .toLowerCase()
          .trim();
      }

      if (![-1, '', null].includes(this.tipo_cliente)) {
        params['filter[internal]'] = this.tipo_cliente
          ? 'internal'
          : 'external';
      }

      return params;
    },

    clearFilters() {
      this.searchPatientTerm = '';
      this.searchTerm = '';
      this.perPage = 10;
      this.tipo_cliente = -1;
      this.gender = -1;

      this.loadPatients();
    },

    onPageChange(page) {
      this.page = page;
      this.loadPatients();
    },

    importPatients() {
      this.$buefy.modal.open({
        parent: this,
        component: PatientImportModal,
        trapFocus: true,
        hasModalCard: true,
        canCancel: ['x'],
        events: {
          close: (data) => data && this.loadPatients(),
        },
      });
    },

    exportPatients() {
      const filters = this.collectFilters();

      PatientsService.export(filters).then(({ data }) => {
        const fileName = data.split('/')[data.split('/').length - 1];
        const fileLink = document.createElement('a');

        fileLink.href = data;
        fileLink.setAttribute('download', fileName);
        fileLink.setAttribute('style', 'display:none;');

        document.body.appendChild(fileLink);
        fileLink.click();

        fileLink.remove();
      });
    },

    toMoney(value) {
      return value
        ? parseFloat(value).toFixed(2).replace(',', '.').replace('.', ',')
        : (0).toFixed(2).replace(',', '.').replace('.', ',');
    },

    onPatientDeleteClick(patientId) {
      this.$buefy.dialog.confirm({
        message: 'Deseja realmente excluir esse cliente?',
        onConfirm: () => this.deletePatient(patientId),
      });
    },

    deletePatient(patientId) {
      PatientsService.destroy(patientId)
        .then(() => {
          this.$buefy.snackbar.open('Cliente excluído com sucesso!');
          this.loadPatients();
        })
        .catch(({ response: { data, status } }) => {
          let messageNotify = '';

          if (status === 403) {
            messageNotify = data.message;
          } else {
            messageNotify = 'Erro inesperado ao excluír cliente';
          }

          this.$buefy.snackbar.open({
            message: messageNotify,
            type: 'is-danger',
            position: 'is-bottom',
            duration: 5000,
            pauseOnHover: true,
          });
        });
    },

    money(value) {
      return (
        'R$ ' + parseFloat(value).toFixed(2).replace(',', '.').replace('.', ',')
      );
    },

    toDate(value) {
      if (value) {
        return moment(new Date(value)).format('DD/MM/YYYY');
      }
    },

    isIconAnimation() {
      if (this.isInitAnimation) {
        return '';
      }

      return this.isCollapse ? 'arrow-top' : 'arrow-bottom';
    },

    isAfter30Minutes(schedule) {
      const current_date = moment();

      return moment(schedule.start).diff(current_date, 'minutes') >= 30;
    },

    getGenderLabel(key) {
      return GENDERS.filter((gender) => gender.key == key)[0].name;
    },
  },
};
</script>

<style lang="scss" scoped>
.arrow-icon {
  color: #353535;
  width: 25px;
  height: 25px;
  transition: transform 0.3s ease-in-out;

  &.arrow-top {
    transform: rotate(180deg);
  }

  &.arrow-bottom {
    transform: rotate(0deg);
  }
}

.container-filters {
  overflow-x: auto;
  min-width: 100%;
}

.filter-actions,
.filter-custom {
  & svg.b-icon {
    font-size: 18px;
    vertical-align: middle;
    margin-bottom: 2px;
  }
}

.filter-actions {
  & svg.b-icon {
    margin-right: 5px;
  }
  & svg.b-icon.bi-search {
    font-size: 16px;
  }

  & svg.b-icon.bi-plus {
    font-size: 22px;
  }
}

.filter-custom {
  & button {
    background-color: transparent;
    border-color: var(--color-secondary);

    & svg.b-icon {
      color: var(--color-secondary);
    }
  }
}

.box-checkbox-filter-others {
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkbox-filter-others {
  padding: 0;
  font-size: 0.8rem;
  font-weight: 600;
}

.table-toggle-button.disabled {
  opacity: 1;
}

.column-custom {
  width: 180px;
  padding: 0 15px;
  z-index: 2000;

  &.id {
    width: 50px;
  }

  &.name,
  &.email {
    min-width: 200px;
  }

  &.cpf,
  &.phone {
    width: 150px;
  }

  &.gender,
  &.schedules_sum {
    width: 120px;
  }

  &.active {
    width: 90px;
  }

  &.options {
    width: auto;
  }
}
</style>
